import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.1.6_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@_5y6qz7mvtjv64apvwnxbparusq/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.1.6_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@_5y6qz7mvtjv64apvwnxbparusq/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.1.6_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@_5y6qz7mvtjv64apvwnxbparusq/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/font.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./oktah-neue-var.ttf\",\"variable\":\"--font-oktah-neue\",\"weight\":\"15 190\"}],\"variableName\":\"oktah\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
